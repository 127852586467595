import { Transform } from "stream";
function Upload() {
	const style ={
		enableBackground:'new 0 0 417.535 417.535',
		height: '100px',
	}
  return (
	
	<>
	<div className="upload-to-cloud-svg">		
			
 			<svg version="1.1" id="Capa_1"   x="0px" y="0px"
				viewBox="0 0 417.535 417.535" style={style} >
			<g>
				<path d="M260.457,238.26c-7.988-9.074-19.384-15.084-29.388-21.768c-7.635-5.102-16.289-13.192-26.246-11.084
					c-9.321,1.974-17.559,11.073-24.929,16.584c-7.731,5.781-16.212,10.578-20.061,19.726c-3.564,8.468,6.932,18.181,15.142,15.142
					c8.232-3.047,15.133-8.538,22.052-13.978c2.252,46.618-4.045,94.311-1.807,140.742c0.805,16.714,23.977,16.207,25.609,0
					c3.267-32.491,2.462-65.735,2.507-98.382c0.019-13.837,1.061-27.992-0.531-41.517c8.142,5.787,16.279,11.595,25.824,14.855
					C260.913,262.778,267.896,246.709,260.457,238.26z"/>
				<path d="M343.64,196.217c10.32-75.757-26.507-175.252-116.783-174.649C151.559,22.07,94.673,89.797,95.147,161.816
					c-50.64-11.952-102.718,25.093-94.233,82.329c12.344,83.266,117.238,67.965,176.81,68.574c2.108,0.022,3.632-0.974,4.644-2.347
					c1.822-0.607,3.497-1.627,4.326-3.214c4.876-9.323,1.763-22.46,1.228-32.628c-0.135-2.582-1.977-4.681-4.209-5.743
					c-2.543-2.668-7.23-3.649-10.607-0.781c-10.494,8.909-24.995-6.878-27.127-15.772c-3.002-12.529,5.121-21.834,13.972-29.242
					c15.509-12.98,34.167-22.082,50.274-34.353c11.336-0.618,22.721,10.98,30.172,16.987c8.332,6.716,17.289,13.118,25.071,20.483
					c9.45,8.945,20.101,23.474,9.371,35.201c-6.934,7.579-20.629,6.151-29.921,5.71c-6.375-0.303-9.119,4.65-8.622,9.488
					c-0.247,0.613-0.49,1.233-0.556,1.934c-0.802,8.425-4.546,21.859,3.2,27.863c0.217,0.168,0.457,0.248,0.68,0.395
					c0.405,2.419,2.034,4.504,5.267,5.085c34.349,6.173,69.745,3.416,104.162-1.232c24.488-3.306,52.911-10.696,63.781-35.789
					C433.191,227.748,383.279,201.053,343.64,196.217z"/>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			</svg>
			</div>
		</>
  )
}
export default Upload;



