import React from "react";

function Downarrow()
{
    const style ={
		enableBackground:'new 0 0 124.075 124.075',
		height: '25px',
	}
    return(
        <>
            <svg version="1.1" id="Capa_1"   x="0px" y="0px"
	            width="124.075px" height="124.075px" viewBox="0 0 124.075 124.075" style={style}
	        >
            <g>
                <path fill="#fff" d="M54.628,120.7c3.5,4.5,10.2,4.5,13.601,0l44.1-54.9c4.4-5.7,0.4-13.8-6.8-13.8h-14.7c-4.7,0-8.2-4.2-8.2-9V8.6
                    c0-4.8-4.2-8.6-9-8.6h-23.1c-4.8,0-8.9,3.8-8.9,8.6V43c0,4.7-3.6,9-8.3,9h-14.8c-7.1,0-11.2,8-6.8,13.7L54.628,120.7z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            </svg>

        </>
    )
}
export default Downarrow;
